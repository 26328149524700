<template>
    <div class="align-div">

       
        <div class="d-flex text-center align-center align-content full-height">
            <img class="img-width" src="@/assets/icons/404.svg">
            <div class="">
            <h1 class="d-flex wrap align-center justify-center fontweight fsize24 word-spacing">PAGE  NOT  FOUND</h1>
        </div>
        </div>
        
      
        <div class="h-60"></div>
    </div>
</template>
<script>
export default {
    data: () => ({

    })
}
</script>

<style>
.img-width {
    width: 42% !important;
}

.align-content {
    align-items: center;
    justify-content: center;
    height: 100vh !important;
}

.align-div{
    text-align: center !important;
}

</style>