<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-container>
        <v-list nav dense>
          <v-list-item-group v-model="model" mandatory active-class="link-active" exact color="primary">
            <v-list-item :to="{ path: '/dashboard' }" mandatory>
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/Status' }">
              <v-list-item-icon>
                <v-icon>mdi-chart-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Status</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/Employee' }">
              <v-list-item-icon>
                <v-icon>mdi-account-group-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Employees</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/Customer' }">
              <v-list-item-icon>
                <v-icon>mdi-account-box-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Customers</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/Project' }">
              <v-list-item-icon>
                <v-icon>mdi-bookmark-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Projects</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/Task' }">
              <v-list-item-icon>
                <v-icon>mdi-script-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Tasks</v-list-item-title>
            </v-list-item>


            <v-list-item :to="{ path: '/Reports' }">
              <v-list-item-icon>
                <v-icon>mdi-file-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Reports</v-list-item-title>
            </v-list-item>

          
            <!-- <v-list-item :to="{ path: '/emp-login' }" v-if="adminRole">
              <v-list-item-icon>
                <v-icon>mdi-toggle-switch-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-0">Employee-Login</v-list-item-title>
            </v-list-item> -->
          </v-list-item-group>
        </v-list>



      </v-container>

    </v-navigation-drawer>
    <v-app-bar color="#2a2a4b" clipped-left dark app class="flex-grow-0 hidden-xs-and-down">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-only"></v-app-bar-nav-icon>
      <div class="d-flex justify-center align-center">
        <v-img max-width="40" width="35" src="../assets/icons/codifi.png"></v-img>
      </div>
      <v-toolbar-title class="ml-7" style="color: white">SmarTask</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom rounded height="3" color="deep-orange accent-3
"></v-progress-linear>
      <v-toolbar-items class="hidden-sm-and-down"> </v-toolbar-items>


      <v-menu offset-y open-on-hover transition="slide-y-transition" class="align-menu" bottom>
        <template v-slot:activator="{ on }">

          <v-icon v-on="on" color="white" class="mr-4">mdi-dots-vertical</v-icon>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="primary">
                <span class="white--text text-h5">{{ firtslast }}</span>
              </v-avatar>

              <h3 class="mt-3">{{ name }}</h3>
              <p class="text-body mt-1 mb-0">
                {{ empEmailID }}
              </p>




            </div>
          </v-card-text>
        </v-card>

      </v-menu>
      <v-icon :ripple="false" @click="confirm()">mdi-location-exit</v-icon>
      <v-dialog v-model="dailog" max-width="720" elevation="0" flat style="overflow: hidden !important;">
        <v-card height="250" style="overflow: hidden;">
          <v-card-title class="headline justify-center white--text text" style="background-color: #0D263B;">Confirmation
          </v-card-title>

          <v-card-text>
            <v-container>

              <v-row class="mt-4">
                <div>
                  <span style="width:30px;">&#33;</span><span class="black--text ml-2" style="font-size:16px">If you
                    want
                    to checkout make sure you click on checkout and logout </span>
                </div>
              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn class="ma-2" color="error" @click="checkout()">
              Check-out and Logout
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" color="primary" @click="logout()">
              Logout
            </v-btn>
            <v-btn class="ma-2" color="error" @click="cancel">
              Exit
            </v-btn>
          </v-card-actions>


        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-main class="bg-clr">
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "dashboard",


  data: () => ({
    menuList: [
      { text: "Dashboard", icon: 'mdi-view-dashboard-outline' },
      { text: 'Status', icon: 'mdi-chart-multiple' },
      { text: "Employee", icon: 'mdi-account-group-outline' },
      { text: "Customer", icon: 'mdi-account-box-multiple-outline' },
      { text: "Project", icon: 'mdi-bookmark-multiple-outline' },
      { text: "Task", icon: 'mdi-script-text-outline' },
      { text: "Reports", icon: 'mdi-note-outline' },
    ],
    showMenu: false,
    dailog: false,
    dialog2: false,
    adminRole:false,
    fullName: "",
    firtslast: "",
    name: "",
    empEmailID: "",
    userID: "",
    drawer: null,
    empRoleId:"",
    model: 0,
  }),

  computed: {
    ...mapState(['loading']),
    ...mapGetters("userData", ["getEmployeeDetail", 'emp_name', 'emp_email', 'emp_id']),
  },

  created() {
    const fullName = this.$store.state.userData.emp_name.split(" ");
    this.firtslast = fullName.shift().charAt(0);
    this.name = this.$store.state.userData.emp_name;
    this.userID = this.$store.state.userData.emp_id;
    this.empEmailID = this.$store.state.userData.emp_email
  },
  mounted() {
    this.model = this.menuList.findIndex(std => std.text === this.$router.currentRoute.path.replace('/', ''));
    if(this.empRoleId = (localStorage.getItem('adminRole') == 1)){
      this.adminRole = true
    }
    else{
      this.adminRole = false
    }
  },

  methods: {
    getImgUrl(img) {
      var images = require.context("../assets/menu/", false, /\.svg$/);
      return images("./" + img + ".svg");
    },

    changeRoute(val) {
      this.$router.push("/" + val.text).catch(() => { });
    },
    selectSection(item) {
      this.selectedSection = item;
      console.log(this.selectedSection);
    },
    confirm() {
      this.dailog = true;
    },
    cancel() {
      this.dailog = false;
    },
    logout() {
      this.dailog = false;
      localStorage.clear();
      this.$router.push("/");
    },
    checkout() {
      this.$store.dispatch("userData/userCheckOut")
      this.$router.push("/");
    },
  }
};
</script>

<style>
.page {
  display: grid;
  grid-template-columns: 72px 1fr 400px;
  grid-template-rows: 1fr 400px;
  grid-template-areas:
    "sidebar main main"
    "sidebar main main" !important;
  height: 100vh !important;
}

#concept {
  grid-area: main;
  height: calc(100vh - 64px);
  margin-top: 64px;

}

#sidebar {
  grid-area: sidebar;
  position: fixed;
  margin-top: 64px;
}

#content1 {
  grid-area: content1;
  border-left: solid 1px #a2a2a2;
}

.bg-clr {
  background-color: rgb(0 0 0 / 6%);
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}


.v-application .primary--text {
  color: #0063c5 !important;
  caret-color: #ff5252 !important;
}

.v-list--dense .v-list-item .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title {
  font-size: 14px !important;
  font-weight: -1;
  line-height: 1rem;
}
</style>